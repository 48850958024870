.form-link-field {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 7px;
    padding: 15px 10px 10px 10px;

    .ant-select {
        margin-bottom: 7px;
    }

    input {
        margin-bottom: 7px;
    }

    label {
        margin-bottom: 7px;
    }
}