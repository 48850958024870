.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-item-selected {
  background-color: rgba(42, 19, 46, 1) !important;
}

.ant-menu-item.buttonLogout{
  margin-top: 50px;
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    .ant-menu-item {
      a {
        color: #626262;
        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}