.ant-form-item-explain, .ant-form-item-extra {
  min-height: 0px;
}
.ant-drawer-content-wrapper {
  @media screen and (min-width: 1000px) {
    width: 55vw !important;
  }  
}
.ant-drawer-body {
  .field-array {
    padding-top: 22px;
  }
  .ant-form-item-label {
    padding-bottom: 0px !important;  
  }
  .ant-form-item-label > label {
    height: 20px !important;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .ant-btn-group {
    padding-top: 22px;
  }
  .ant-form-item-label > label {
    font-weight: 500;
  }
  .ant-form-item-label label[for=root_items__title] {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 11px;
  }
}

